



































































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsPersonalInformationContactViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-personal-information-contact-view-model';

const SERVICES = {
  allianz: 'ContractSavingsPersonalInformationContactAllianz',
  kuspit: 'ContractSavingsPersonalInformationContactKuspit',
};

@Component({
  name: 'ContractSavingsPersonalInformationContact',
  components: {
    ContractSavingsPersonalInformationContactAllianz: () => import('./personal-information/contact/ContractSavingsPersonalInformationContactAllianz.vue'),
    ContractSavingsPersonalInformationContactKuspit: () => import('./personal-information/contact/ContractSavingsPersonalInformationContactKuspit.vue'),
  },
})
export default class ContractSavingsPersonalInformationContact extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  personal_info_contact_view_model = Vue.observable(
    new ContractSavingsPersonalInformationContactViewModel(),
  );

  current_component = SERVICES[this.service];

  @Watch('personal_info_contact_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const access_to_contact_form = this.$refs.save_contact_form as any;
    const saved = await access_to_contact_form.saveStep();
    if (saved) {
      this.$emit('nextStep');
    }
  }

  created() {
    this.personal_info_contact_view_model.small_screen = getScreenSizeVariant() === 'small';
    this.$emit('loadingInfo', this.personal_info_contact_view_model.is_loading);
  }
}

