import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

export default class ContractSavingsPersonalInformationContactViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQuery!: GetPersonQueryService;

  i18n_namespace = 'components.contract-savings.personal-information.contact';

  contact_form = false;

  small_screen: null | boolean = null;

  is_loading = true;

  get userName() {
    return this.getPersonQuery.execute().name;
  }
}
